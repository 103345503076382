import MainMenuStructure from 'model/local/main-menu-structure'
export default new MainMenuStructure(
  new MainMenuStructureElement(
    'Аналитическая панель',
    'icofont icofont-chart-bar-graph',
    '',
    true,
    false,
    '',
    // new MainMenuStructureElement(
    //   'Главная',
    //   'icofont icofont-home',
    //   '/analytic/index'),
    // new MainMenuStructureElement(
    //   'Назначение диагностики',
    //   'icofont icofont-bag-alt',
    //   '/analytic/index')
    // ,
    new MainMenuStructureElement(
      'Результаты диагностики',
      'fa fa-stethoscope',
      '',
      true,
      false,
      'main-menu-element-appointment-special-highlight',
      new MainMenuStructureElement(
        'Данные по участникам',
        'icofont icofont-dashboard-web',
        '/analytic/members-data'),
      // new MainMenuStructureElement(
      //   'Рейтинг регионов',
      //   'fa fa-flag',
      //   '/analytic/rate-regions'),
      new MainMenuStructureElement(
        'Рейтинг районов',
        'fa fa-flag-checkered',
        '/analytic/rate-districts'),
      new MainMenuStructureElement(
        'Рейтинг школ',
        'icofont icofont-chart-histogram',
        '/analytic/rate-schools'),
      new MainMenuStructureElement(
        'Рейтинг учителей',
        'icofont icofont-chart-line-alt',
        '/analytic/rate-teachers'),
      new MainMenuStructureElement(
        'Рейтинг компетенций',
        'fa fa-sort-numeric-asc',
        '/method/rating/codifiers'),
      new MainMenuStructureElement(
        'Диаграммы',
        'icofont icofont-star-alt-2',
        '/analytic/diagrams')
    )
    /*,
    new MainMenuStructureElement(
      'Назначение обучения',
      'icofont icofont-address-book',
      '/analytic/appointments',
      false,
      false,
      '')*/
    // ,
    // new MainMenuStructureElement(
    //   'Результаты обучения',
    //   'fa fa-list-ol',
    //   '',
    //   true,
    //   false,
    //   'main-menu-element-appointment-special-highlight',
    //   new MainMenuStructureElement(
    //     'История назначений',
    //     'icofont icofont-history',
    //     '/analytic/index'),
    //   new MainMenuStructureElement(
    //     'Данные по участникам',
    //     'icofont icofont-data',
    //     '/analytic/index'),
    //   new MainMenuStructureElement(
    //     'Оценка эффективности',
    //     'icofont icofont-exchange',
    //     '/analytic/index')
    // )
  ),
  new MainMenuStructureElement(
    'Aдминистрирование',
    'icofont icofont-settings',
    '',
    true,
    false,
    '',
    // new MainMenuStructureElement(
    //   'Назначение на тесты',
    //   'icofont icofont-ui-pointer',
    //   '/appointments'),
    // new MainMenuStructureElement(
    //   'Ход тестирования',
    //   'icofont icofont-check',
    //   '/tracks'),
    new MainMenuStructureElement(
      'Настройки',
      'icofont icofont-ui-settings',
      'http://api.iroso.ru/admin/',
      false,
      true)
  ),
  new MainMenuStructureElement(
    'Конструктор тестов',
    'icofont icofont-vehicle-crane',
    '/constructor-categories'),
  new MainMenuStructureElement(
    'Конструктор курсов',
    'icofont icofont-education',
    '/course-list',
    false,
    true
  ),
  new MainMenuStructureElement(
    'Личный кабинет',
    'fa fa-user-o',
    '',
    true,
    false,
    '',
    new MainMenuStructureElement(
      'Главная',
      'icofont icofont-home',
      '/index'),
    new MainMenuStructureElement(
      'Мой профиль',
      'fa fa-address-card-o',
      '/profile'),
    new MainMenuStructureElement(
      'Моя диагностика',
      'icofont icofont-chart-line-alt',
      '/diagnostika'),
    new MainMenuStructureElement(
      'Моё обучение',
      'fa fa-graduation-cap',
      '/courses/my'),
    new MainMenuStructureElement(
      'Мои сертификаты',
      'icofont icofont-certificate',
      '/certs')
  )
)

import MainMenuStructureElement from 'model/local/main-menu-structure-element'
