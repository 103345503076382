import BaseModel from './BaseModel'
import TestData from './TestData'
import Project from './Project'

export default class TestCategory extends BaseModel {
  static id: string = ''
  public name: string = ''
  public projectName: string = ''
  public doomsday: number = 0
  public description: string = ''
  public hidden: boolean = false
  public tests: TestData[] = []
}
