import BaseModel from './BaseModel'
import TestType from './TestType'
import Subtest from './SubtestData'
import Subject from './Subject'
import TestCategory from './TestCategory'

export default class Test extends BaseModel {
  public id: string = ''
  public code: string = ''
  public name: string = ''
  public ready: boolean = true
  public runOnlyOnce: boolean = null
  public cannotRunAnyMore: boolean = null
  public previousRunDate: string = null
  public previousRunMark: string = null
  public description: string = ''
  public timeText: string = ''
  public timeTextAddon: string = ''
  public videoIntro: string = null
  public videoStart: string = null
  public videoIntroIsActive: boolean = true
  public videoStartIsActive: boolean = true
  public text: string = ''
  public redZonePercent: number = null
  public yellowZonePercent: number = 50
  public greenZonePercent: number = 80
  public category: TestCategory = new TestCategory()
  public type: TestType = new TestType()
  public subtests: Subtest[] = []
  public subjects: Subject[] = []
}
